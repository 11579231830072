@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

.animated-switch {
  position: relative;
}

.animated-switch > div {
  position: absolute;
}

#miles-filter-container,
#miles-filter-container-mobile {
  position: relative;
  display: inline-block;
}

#pet-info {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* loading spinner from https://projects.lukehaas.me/css-loaders/ */

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(124, 58, 237, 0.2);
  border-right: 1.1em solid rgba(124, 58, 237, 0.2);
  border-bottom: 1.1em solid rgba(124, 58, 237, 0.2);
  border-left: 1.1em solid #7c3aed;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
